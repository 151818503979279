import React from 'react';
import Section from '@latitude/section';
import Button from '@/components/lab-components/Button';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './_offer_banner.scss';
import { BUTTON_THEME, TEXT_TYPE } from '@/utils/constants';
import { BLOCKS } from '@contentful/rich-text-types';

const OfferBanner = ({ 
  offerBannerCards
}) => {
  const colClass = offerBannerCards.length === 1 ? "col-12" : "col-lg-5 col-sm-12";
  const headingOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <h3 className="offer-heading">{children}</h3>
      )
    }
  }

  return (
    <Section className="offer-banner">
      <div class="row offer">
        {offerBannerCards.map((offerCard, index) => (
          <div className={`offer-card ${colClass}`} key={index}>
            <div className="offer-ribbon">
              <span className='white-star'>&#9733;</span>{offerCard?.ribbon}
            </div>
            <div className="offer-text">
              {offerCard?.heading && 
                documentToReactComponents(offerCard?.heading, headingOptions)}
              {offerCard?.description && 
                documentToReactComponents(offerCard?.description)}
              <Button
                theme={BUTTON_THEME.APPLICATION}
                href={offerCard?.primaryButton?.url}
                target="_blank"
                trackId="promo-banner"
                trackEventData={{
                  category: 'cta',
                  action: 'quote-link',
                  location: 'gem-visa'
                }}
                trackProductId={offerCard.trackId || ""}
                className="max-width-fit-content"
                text={offerCard.primaryButton?.text}
              />
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default OfferBanner;
